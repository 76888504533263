import { Injectable, Inject } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from "rxjs";
import { API_CONFIG, Config } from './config';

@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(@Inject(API_CONFIG) 
  
  public apiConfig: Config) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

    const clonedRequest = req.clone({
      headers: req.headers.set('Content-Type', 'application/json')
    });

    return next.handle(clonedRequest);
  }
}