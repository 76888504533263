import { Component, OnInit, HostListener } from '@angular/core';
import { EventsService } from 'app/shared/services/events-service';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})
export class LicenseComponent implements OnInit {
  showModal: boolean;

  constructor(private eventsService: EventsService) { }

  ngOnInit() {
  }

  @HostListener('document:licenseFormShow', ['$event'])
  show() {
    this.showModal = true;

    this.eventsService.throwEvent("setBlockOfContentScroll", { status: true });
  }

  hide() {
    this.showModal = false;

    this.eventsService.throwEvent("setBlockOfContentScroll", { status: false });
  }
}
