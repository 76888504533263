import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'app/shared/services/user-service';
import { EventsService } from 'app/shared/services/events-service';
import { API_CONFIG, Config } from './../../shared/config';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  title = 'angulartoastr';
  showModal: boolean;
  registerForm: FormGroup;
  submitted = false;
  loading: boolean = false;
  captchaResolved: boolean = false;
  captchaActive: boolean = true;

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private eventsService: EventsService,
              @Inject(API_CONFIG) public apiConfig: Config
    ) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  @HostListener('document:loginFormShow', ['$event'])
  show() {

    this.f.email.setValue("");
    this.f.password.setValue("");

    this.showModal = true; // Show-Hide Modal Check
  }
  //Bootstrap Modal Close event
  hide() {
    this.showModal = false;
  }

  register(){
    this.hide();
    this.eventsService.throwEvent("registerFormShow");
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }
  
  onSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      if (this.registerForm.invalid) {
          return;
      }

      if(!this.captchaResolved){
        this.eventsService.showNotification('top','right', "Captcha failed.", 'danger' );
        return;
      }

      this.loading = true;

      this.userService.signIn(this.f.email.value, this.f.password.value, (status: boolean, response) => {

        if(status === true){
          this.loading = false;
          this.submitted = false;

          this.eventsService.showNotification('top','right', "Successuful Sign In", 'success' );

          this.hide();
        } else {
          this.loading = false;
          this.submitted = false;
          this.f.password.setValue("");

          this.eventsService.showNotification('top','right', response.data, 'danger' );

          this.recaptchaErrored(null);
        }

        this.captchaResolved = false;

      });
  }

  recaptchaResolved(event){
    this.captchaResolved = true;
  }

  recaptchaErrored(error){
    this.captchaResolved = false;
    this.captchaActive = false;

    setTimeout(() => {
      this.captchaActive = true;
    }, 100);
  }
}
