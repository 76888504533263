import { InjectionToken } from '@angular/core';

export class Config {
  backendUrl: string;
  reCaptchaKey: string;
}

export const apiConfig:Config = {
  backendUrl: 'https://store.frostweepgames.com/api/v1/api.php',
  reCaptchaKey: '6LdilJ8bAAAAAEP9KPf1rv0-ZNsqd66qqPYppTyJ'
};

export const API_CONFIG = new InjectionToken<Config>('');
