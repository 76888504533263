import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIG, Config } from './../config';
import { EventsService } from './events-service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,
              private eventsService: EventsService,
    @Inject(API_CONFIG) public apiConfig: Config
  ) { };

  signIn(email: string, password: string, callback) {
    let request = {
        api: "signIn",
        data: {
            email: email,
            password: password
        }
    };
    this.http.post(`${this.apiConfig.backendUrl}`, request).subscribe((response: any) => {
        if(response.status === true){
            localStorage.setItem('user-token', response.data);
            this.eventsService.throwEvent("signIn");
        }

        if(callback != null){
            callback(response.status, response);
        }        
    }, (error) => {
        if(callback != null){
            callback(false, error);
        }
    });
  }

  signUp(email: string, password: string, callback) {
    let request = {
        api: "signUp",
        data: {
            email: email,
            password: password
        }
    };
    this.http.post(`${this.apiConfig.backendUrl}`, request).subscribe((response: any) => {
        if(callback != null){
            callback(response.status, response);
        }
    }, (error) => {
        if(callback != null){
            callback(false, error);
        }
    });
  }

  getUserData(callback) {
    let request = {
        api: "getUserInfo",
        data: {
            token: this.getToken()
        }
    };
    this.http.post(`${this.apiConfig.backendUrl}`, request).subscribe((response: any) => {
        if(callback != null){
            callback(response.status, response);
        }
    }, (error) => {
        if(callback != null){
            callback(false, error);
        }
    });
  }

  signOut(){
    localStorage.removeItem('user-token');

    this.eventsService.throwEvent("signOut");
  }

  isSignedIn(callback){
    let token = localStorage.getItem('user-token');

    if(token != null){

        let request = {
            api: "checkAccess",
            data: {
                token: token
            }
        };

        this.http.post(`${this.apiConfig.backendUrl}`, request).subscribe((response: any) => {
            if(callback != null){
                if(response.status === false)
                    this.signOut();

                callback(response.status);
            }
        }, (error) => {
            if(callback != null){
                callback(false);
            }
        });
    } else {
        if(callback != null)
            callback(false);
    }
  }

  getToken(){
    return localStorage.getItem('user-token');
  }

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; SameSite=Strict; path=/";
  }
  
  getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
  }
}