import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { BrowserModule } from '@angular/platform-browser'

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { API_CONFIG, apiConfig } from './shared/config';

import { Interceptor } from './shared/interceptor';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

import { RecaptchaModule } from "ng-recaptcha";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    RecaptchaModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
  ],
  exports: [
  ],
  providers: [
    { 
      provide: API_CONFIG, 
      useValue: apiConfig 
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
