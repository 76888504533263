import { Component, OnInit, HostListener } from '@angular/core';
import { UserService } from 'app/shared/services/user-service';
import { EventsService } from 'app/shared/services/events-service';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    requireAuth: boolean;
}
export const ROUTES: RouteInfo[] = [
 { path: '/assets', title: 'Assets',  icon: 'dashboard', class: '', requireAuth: false  },
 { path: '/user-profile', title: 'Profile',  icon: 'person', class: '', requireAuth: true  },
 { path: '/downloads', title: 'Downloads',  icon: 'library_books', class: '', requireAuth: true },
 //{ path: '/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  isSignedIn: boolean = false;

  constructor(private userService: UserService,
              private eventsService: EventsService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);

    this.userService.isSignedIn((isSignedIn: boolean) => {
      this.isSignedIn = isSignedIn;
   });
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };

  @HostListener('document:signIn', ['$event'])
  signInEventHandler(event){
    this.isSignedIn = true;
  }

  @HostListener('document:signOut', ['$event'])
  signOutEventHandler(event){
    this.isSignedIn = false;
  }

  showLoginForm(){
    this.eventsService.throwEvent("loginFormShow");
    this.eventsService.throwEvent("hideSideBar");
  }

  signOut(){
    this.userService.signOut();
  }
}
