import { Component, OnInit } from '@angular/core';
import { EventsService } from 'app/shared/services/events-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  today : Date = new Date();
  
  constructor(private eventsService: EventsService) { }

  ngOnInit() {
    this.useLiveinternet();
  }

  openEULA(){
    this.eventsService.throwEvent("licenseFormShow");
  }

  useLiveinternet(){
    let d = document;
    let s = screen;

    (<HTMLImageElement>d.getElementById("licnt7CEE")).src=
    "https://counter.yadro.ru/hit?t26.6;r"+escape(d.referrer)+
    ((typeof(s)=="undefined")?"":";s"+s.width+"*"+s.height+"*"+
    (s.colorDepth?s.colorDepth:s.pixelDepth))+";u"+escape(d.URL)+
    ";h"+escape(d.title.substring(0,150))+";"+Math.random();
  };
}
